<template>
<h1 class="hero-title">My GPS Workouts</h1>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style scoped lang="scss">
$title-overlay-color: #000;

.hero-title{
  display: block;
  background-color: $title-overlay-color;
  margin: auto;
  width: 100%;
  text-align: center;
  padding: 40px 0;
  opacity: 0.5;
  color:white;
  font-size: 48px;
  font-weight: 800;
}
</style>
