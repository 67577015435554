export default class {
  constructor(method, path) {
    this.options = {
      method,
      headers: {},
      credentials: 'include',
      body: undefined,
    };
    this.url = `${process.env.VUE_APP_API_URL}${path}`;
    this.middlewares = [];
  }

  addHeader(name, value) {
    this.options.headers[name] = value;
    return this;
  }

  setJsonBody(data) {
    this.options.body = JSON.stringify(data);
    this.addHeader('Content-Type', 'application/json');
    return this;
  }

  setBody(data) {
    this.options.body = data;
    return this;
  }

  addMiddleware(middleware) {
    this.middlewares.push(middleware);
    return this;
  }

  build() {
    return new Request(this.url, this.options);
  }
}
