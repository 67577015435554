<template>
  <div v-if="isSyncing" class="navbar-item">
    <font-awesome-icon icon="circle-notch" class="fa-spin"></font-awesome-icon>
    <span class="ml-1"> {{ current }}/{{ total || '?' }} </span>
  </div>
</template>

<script>
import apiClient from '@/services/api/client';
import { mapState } from 'vuex';

export default {
  name: 'AutoSync',
  data() {
    return {
      workouts: [],
      summary: [],
      current: 0,
      isSyncing: false,
    };
  },
  computed: {
    ...mapState('profile', ['user']),
    total() {
      return this.workouts.length;
    },
  },
  watch: {
    user(user) {
      if (!user) {
        return;
      }
      if (!user.integrations.strava.athleteId) {
        return;
      }
      this.sync();
    },
  },
  methods: {
    async sync() {
      try {
        await this.fetchNewStravaWorkouts();
      } catch (err) {
        console.error(err);
        this.$store.dispatch('notification/setError', 'Autosync failed');
        this.isSyncing = false;
        return;
      }

      if (this.workouts.length) {
        this.isSyncing = true;
      }

      for (let i = 0; i < this.workouts.length; i += 1) {
        try {
          // don't want to generate possibly too many promises at once
          // too lazy for chunking
          // eslint-disable-next-line no-await-in-loop
          await apiClient.fetch('strava/sync', this.workouts[i].id);
          this.summary.push({
            ...this.workouts[i],
            status: 'success',
          });
        } catch (error) {
          this.summary.push({
            ...this.workouts[i],
            status: error.isDuplicate() ? 'warning' : 'error',
            errorMessage: error.getMessage(),
          });
        }
        this.current += 1;
      }

      if (this.workouts.length) {
        this.isSyncing = false;
        this.$store.dispatch('notification/addSuccess', `Synced ${this.workouts.length} new workout${this.workouts.length > 1 ? 's' : ''}.`);
      }
    },
    async getStravaFilters() {
      const limit = 50;
      const workouts = await apiClient.fetch('workout/find', { limit: 1 });
      if (!workouts || !workouts.results.length) {
        return {
          after: '2001-01-01T00:00:00.000Z', // undocumented but forces order to ASC
          limit,
        };
      }
      const [{ startTime }] = workouts.results.slice(0);
      return {
        after: startTime,
        limit,
      };
    },
    async fetchNewStravaWorkouts() {
      const filters = await this.getStravaFilters();
      let chunk = [];

      do {
        // eslint-disable-next-line no-await-in-loop
        chunk = await await apiClient.fetch('strava/getWorkouts', filters);
        if (chunk.results && chunk.results.length) {
          chunk.results.forEach((workout) => this.workouts.push(workout));
          filters.after = chunk.results[chunk.results.length - 1].startTime;
        }
      } while (chunk.results.length);
    },
  },
};
</script>
