import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
  },
  {
    path: '/workouts',
    name: 'Workouts',
    meta: { role: 'user' },
    component: () => import(/* webpackChunkName: "workouts" */ '../views/Workouts.vue'),
  },
  {
    path: '/workouts/:startTime(.*)',
    name: 'Workout',
    meta: { role: 'user' },
    component: () => import(/* webpackChunkName: "workouts" */ '../views/Workout.vue'),
  },
  {
    path: '/workouts/import/upload',
    name: 'UploadWorkouts',
    meta: { role: 'user' },
    component: () => import(/* webpackChunkName: "upload" */ '../views/workouts/import/Upload.vue'),
  },
  {
    path: '/workouts/import/strava',
    name: 'ImportStravaWorkouts',
    meta: { role: 'user' },
    component: () => import(/* webpackChunkName: "upload" */ '../views/workouts/import/Strava.vue'),
  },
  {
    path: '/map',
    name: 'Map',
    meta: { role: 'user' },
    component: () => import(/* webpackChunkName: "map" */ '../views/Map.vue'),
  },
  {
    path: '/sports',
    name: 'Sports',
    meta: { role: 'user' },
    component: () => import(/* webpackChunkName: "map" */ '../views/Sports.vue'),
  },
  {
    path: '/sport/:sportId(.*)',
    name: 'Sport',
    meta: { role: 'user' },
    component: () => import(/* webpackChunkName: "map" */ '../views/Sport.vue'),
  },
  {
    path: '/stats',
    name: 'Stats',
    meta: { role: 'user' },
    component: () => import(/* webpackChunkName: "map" */ '../views/Stats.vue'),
    children: [
      {
        path: '/distance/monthly',
        name: 'MonthlyDistanceStats',
        meta: { role: 'user' },
        component: () => import(/* webpackChunkName: "map" */ '../views/stats/distance/Monthly.vue'),
      },
      {
        path: '/distance/yearly',
        name: 'YearlyDistanceStats',
        meta: { role: 'user' },
        component: () => import(/* webpackChunkName: "map" */ '../views/stats/distance/Yearly.vue'),
      },
      {
        path: '/distance/total',
        name: 'TotalDistanceStats',
        meta: { role: 'user' },
        component: () => import(/* webpackChunkName: "map" */ '../views/stats/distance/Total.vue'),
      },
      {
        path: '/duration/monthly',
        name: 'MonthlyDurationStats',
        meta: { role: 'user' },
        component: () => import(/* webpackChunkName: "map" */ '../views/stats/duration/Monthly.vue'),
      },
      {
        path: '/duration/yearly',
        name: 'YearlyDurationStats',
        meta: { role: 'user' },
        component: () => import(/* webpackChunkName: "map" */ '../views/stats/duration/Yearly.vue'),
      },
      {
        path: '/duration/total',
        name: 'TotalDurationStats',
        meta: { role: 'user' },
        component: () => import(/* webpackChunkName: "map" */ '../views/stats/duration/Total.vue'),
      },
    ],
  },
  {
    path: '/settings/profile',
    name: 'ProfileSettings',
    meta: { role: 'user' },
    component: () => import(/* webpackChunkName: "map" */ '../views/settings/Profile.vue'),
  },
  {
    path: '/settings/map',
    name: 'MapSettings',
    meta: { role: 'user' },
    component: () => import(/* webpackChunkName: "map" */ '../views/settings/Map.vue'),
  },
  {
    path: '/settings/change-password',
    name: 'ChangePasswordSettings',
    meta: { role: 'user' },
    component: () => import(/* webpackChunkName: "map" */ '../views/settings/ChangePassword.vue'),
  },
  {
    path: '/settings/connect/strava',
    name: 'StravaConnectSettings',
    meta: { role: 'user' },
    component: () => import(/* webpackChunkName: "map" */ '../views/settings/connect/Strava.vue'),
  },
  {
    path: '/:pathSegments+',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
