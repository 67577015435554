import RequestBuilder from '@/services/api/requestBuilder';

export default {
  find({ filters }) {
    const queryString = filters ? `?${new URLSearchParams(filters).toString()}` : '';
    return new RequestBuilder('GET', `/users/me/workouts${queryString}`);
  },
  upload({ file }) {
    const formData = new FormData();
    formData.append('workout', file);
    return new RequestBuilder('POST', '/users/me/workouts').setBody(formData);
  },
  get({ startTime }) {
    return new RequestBuilder('GET', `/users/me/workouts/${startTime}`);
  },
  delete({ startTime }) {
    return new RequestBuilder('DELETE', `/users/me/workouts/${startTime}`);
  },
};
