<template>
  <Modal
    v-for="(item, index) in items"
    :key="item.id"
    :title="item.type"
    :type="item.type"
    :with-overlay="index === 0"
    @dismissed="this.onDismissed(item.id)"
  >
    {{ item.message }}
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal.vue';

const notificationClasses = {
  error: 'is-danger',
  warning: 'is-warning',
  info: 'is-info',
  success: 'is-success',
};

export default {
  components: {
    Modal,
  },
  name: 'Notifications',
  computed: {
    ...mapState('notification', ['items']),
  },
  methods: {
    getClass(type) {
      return notificationClasses[type] || notificationClasses.info;
    },
    onDismissed(item) {
      this.$store.dispatch('notification/remove', item);
    },
  },
};
</script>
