import {
  format,
  parse,
  startOfMonth,
  addMonths,
  subDays,
  addDays,
} from 'date-fns';

const inputDateFormat = 'yyyy-MM-dd';

export default {
  getMonthlyAfter(date) {
    return startOfMonth(date);
  },
  getMonthlyBefore(date) {
    return addMonths(this.getMonthlyAfter(date), 1);
  },
  getDefaultAfter() {
    return this.getMonthlyAfter(new Date());
  },
  getDefaultBefore() {
    return this.getMonthlyBefore(new Date());
  },
  getMaxAfter(before) {
    return subDays(before, 1);
  },
  getMinBefore(after) {
    return addDays(after, 1);
  },
  formatInput(date) {
    return format(date, inputDateFormat);
  },
  parseInput(date) {
    return parse(date, inputDateFormat, new Date());
  },
};
