<template>
  <div v-if="isLoggedIn" class="navbar-item">
    Logged as&nbsp;
    <router-link :to="{ name: 'ProfileSettings'}">
      {{ user.username }}
    </router-link>
  </div>
  <div v-if="isLoggedIn" class="navbar-item">
    <div class="buttons">
      <AsyncButton @handle="handleLogout">
        <span class="icon is-small">
          <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
        </span>
        <span>Log Out</span>
      </AsyncButton>
    </div>
  </div>
  <div v-if="isLoggedOut" class="navbar-item">
    <div class="buttons">
      <router-link
        v-if="!isOnRegisterPage"
        class="button is-primary"
        :to="{ name: 'Register' }"
      >
        Sign Up
      </router-link>
      <router-link
          v-if="!isOnLoginPage"
        class="button is-light"
        :to="{ name: 'Login' }"
      >
        Log In
      </router-link>
    </div>
  </div>
  <div v-if="isPending" class="navbar-item">
     Please wait...
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AsyncButton from '@/components/AsyncButton.vue';

export default {
  name: 'PageHeaderProfile',
  components: {
    AsyncButton,
  },
  computed: {
    isOnRegisterPage() {
      return this.$route.name === 'Register';
    },
    isOnLoginPage() {
      return this.$route.name === 'Login';
    },
    ...mapState('profile', ['user']),
    ...mapGetters('auth', ['isLoggedIn', 'isLoggedOut', 'isPending']),
  },
  methods: {
    async handleLogout() {
      await this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'Home' });
    },
  },
};
</script>
