import apiClient from '@/services/api/client';

export default {
  namespaced: true,
  state: () => ({
    itemsPromise: undefined,
    items: {},
    fetched: false,
  }),
  mutations: {
    setAll(state, items) {
      state.items = {};
      items.forEach((item) => { state.items[item.id] = item; });
      state.fetched = true;
    },
    set(state, item) {
      state.items[item.id] = item;
    },
    setAllPromise(state, itemsPromise) {
      state.itemsPromise = itemsPromise;
    },
    clear(state) {
      state.items = {};
      state.itemsPromise = null;
      state.fetched = false;
    },
    update(state, sport) {
      state.items[sport.id] = sport;
    },
    delete(state, sport) {
      delete state.items[sport.id];
    },
    addImportName(state, { sportId, importName }) {
      if (state.items[sportId].importNames) {
        state.items[sportId].importNames.push(importName);
      }
    },
    deleteImportName(state, { sportId, importName }) {
      if (!state.items[sportId].importNames) {
        return;
      }

      const index = state.items[sportId].importNames.indexOf(importName);

      if (index !== -1) {
        state.items[sportId].importNames.splice(index, 1);
      }
    },
  },
  actions: {
    async fetchAll({ commit, state }) {
      const promise = apiClient.fetch('sport/getAll')
        .then((response) => {
          commit('setAll', response.results);
          return state.items;
        });

      commit('setAllPromise', promise);

      return promise;
    },
    async getAll({ state, dispatch }) {
      if (state.fetched) {
        return state.items;
      }

      if (state.itemsPromise) {
        return state.itemsPromise;
      }

      return dispatch('fetchAll');
    },
    async fetchDetailed({ commit }, id) {
      const promise = apiClient.fetch('sport/get', id)
        .then((sport) => {
          commit('set', sport);
          return sport;
        });
      return promise;
    },
    async update({ commit }, sport) {
      const updatedSport = await apiClient.fetch('sport/update', sport);
      commit('update', updatedSport);
    },
    async delete({ commit }, sport) {
      await apiClient.fetch('sport/delete', sport);
      commit('delete', sport);
    },
    async addImportName({ commit }, { sportId, importName }) {
      await apiClient.fetch('sport/addImportName', { sportId, importName });
      commit('addImportName', { sportId, importName });
    },
    async deleteImportName({ commit }, { sportId, importName }) {
      await apiClient.fetch('sport/deleteImportName', { sportId, importName });
      commit('deleteImportName', { sportId, importName });
    },
  },
  getters: {
    getById: (store) => (id) => store.items[id] || {},
  },
};
