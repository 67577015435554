import ApiError from '@/error/apiError';

const getBody = async (response) => {
  const contentType = response.headers.get('Content-Type') || '';
  if (contentType.includes('json')) {
    return response.json();
  }

  return response.text();
};

const fetchWrapper = async (requestBuilder) => {
  let response;
  try {
    response = await fetch(requestBuilder.build());
  } catch (error) {
    console.error(error);
    return ApiError.fromResponse({}, response.status);
  }

  const body = await getBody(response);

  if (response.status >= 400) {
    console.log('wrong status');
    throw ApiError.fromResponse(body, response.status);
  }

  return body;
};

const getNextStep = async (requestBuilder, steps) => {
  if (steps.length === 0) {
    return null;
  }
  return steps[0](requestBuilder, () => (
    getNextStep(requestBuilder, steps.slice(1))
  ));
};

const dispatch = async (requestBuilder) => {
  const steps = requestBuilder.middlewares.concat([fetchWrapper]);
  const result = await getNextStep(requestBuilder, steps);
  return result;
};

export default dispatch;
