<template>
<div class="page-container bg-img">
  <PageHeader/>
  <Notifications/>
  <router-view/>
</div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import '@/assets/styles/main.scss';
import Notifications from './components/Notifications.vue';

export default {
  name: 'App',
  created() {
    this.$store.dispatch('auth/fetchLoggedUserData');
  },
  components: {
    PageHeader,
    Notifications,
  },
};
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.bg-img {
  background-image: url('~@/assets/images/background.jpg') ;
  background-position: center center;
  background-repeat:  no-repeat;
  background-attachment: fixed;
  background-size:  cover;
 }
</style>
