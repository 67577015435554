import apiClient from '@/services/api/client';

export default {
  namespaced: true,
  state: () => ({
    itemsPromise: undefined,
    items: [],
    fetched: false,
  }),
  mutations: {
    setAll(state, items) {
      state.items = items;
      state.fetched = true;
    },
    setAllPromise(state, itemsPromise) {
      state.itemsPromise = itemsPromise;
    },
  },
  actions: {
    async fetchAll({ commit, state }) {
      const promise = apiClient.fetch('stats/getAll')
        .then((response) => {
          commit('setAll', response);
          return state.items;
        });

      commit('setAllPromise', promise);

      return promise;
    },
    async getAll({ state, dispatch }) {
      if (state.fetched) {
        return state.items;
      }

      if (state.itemsPromise) {
        return state.itemsPromise;
      }

      return dispatch('fetchAll');
    },
  },
  getters: {
    years(store) {
      return store.items.map((sportStats) => Object.keys(sportStats.durationSeconds.yearly))
        .flat()
        .filter((x, i, a) => a.indexOf(x) === i); // remove duplicates
    },
    minYear(store, getters) {
      return getters.years ? Math.min(...getters.years) : undefined;
    },
    maxYear(store, getters) {
      return getters.years ? Math.max(...getters.years) : undefined;
    },
    yearsRange(store, getters) {
      const min = getters.minYear;
      const max = getters.maxYear;

      if (!min || !max) {
        return [];
      }
      return Array.from({ length: max - min + 1 }, (_, i) => min + i); // min-max range
    },
  },
};
