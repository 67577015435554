import { createStore } from 'vuex';
import auth from './auth';
import profile from './profile';
import sport from './sport';
import notification from './notification';
import workoutFilter from './workoutFilter';
import workoutStats from './workoutStats';

export default createStore({
  modules: {
    auth,
    profile,
    sport,
    notification,
    workoutFilter,
    workoutStats,
  },
});
