import RequestBuilder from '@/services/api/requestBuilder';

export default {
  getAll() {
    return new RequestBuilder('GET', '/users/me/sports');
  },
  get(id) {
    return new RequestBuilder('GET', `/users/me/sports/${id}`);
  },
  update(sport) {
    return new RequestBuilder('PATCH', `/users/me/sports/${sport.id}`).setJsonBody(sport);
  },
  delete({ id }) {
    return new RequestBuilder('DELETE', `/users/me/sports/${id}`);
  },
  addImportName({ sportId, importName }) {
    return new RequestBuilder('POST', `/users/me/sports/${sportId}/importNames`).setJsonBody({ importName });
  },
  deleteImportName({ sportId, importName }) {
    return new RequestBuilder('DELETE', `/users/me/sports/${sportId}/importNames/${importName}`);
  },
};
