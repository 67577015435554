<template>
  <router-link
    v-if="isLoggedIn"
    class="navbar-item"
    active-class="is-active"
    :to="{ name: 'Workouts' }"
  >
    Workouts
  </router-link>
  <router-link
    v-if="isLoggedIn"
    class="navbar-item"
    active-class="is-active"
    :to="{ name: 'Map' }"
  >
    Map
  </router-link>
  <div v-if="isLoggedIn" class="navbar-item has-dropdown is-hoverable" ref="dropdown">
    <a class="navbar-link">
      Import
    </a>
    <div class="navbar-dropdown" @click="hideSubmenu()">
      <router-link
        class="navbar-item"
        active-class="is-active"
        :to="{ name: 'UploadWorkouts' }"
      >
        Upload
      </router-link>
      <router-link
        v-if="isStravaConnected"
        class="navbar-item"
        active-class="is-active"
        :to="{ name: 'ImportStravaWorkouts' }"
      >
        Strava
      </router-link>
    </div>
  </div>
  <router-link
    v-if="isLoggedIn"
    class="navbar-item"
    active-class="is-active"
    :to="{ name: 'Sports' }"
  >
    Sports
  </router-link>
    <router-link
    v-if="isLoggedIn"
    class="navbar-item"
    active-class="is-active"
    :to="{ name: 'MonthlyDistanceStats' }"
  >
    Stats
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PageHeaderNav',
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('profile', ['isStravaConnected']),
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'Home' });
    },
    hideSubmenu() {
      // https://github.com/jgthms/bulma/issues/2514
      // the beauty of frontend ¯\_(ツ)_/¯
      this.$refs.dropdown.classList.remove('is-hoverable');
      setTimeout(() => {
        this.$refs.dropdown.classList.add('is-hoverable');
      }, 100);
    },
  },
};
</script>
