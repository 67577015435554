<template>
<div class="modal" :class="{ 'is-active': isActive }">
  <div v-if="withOverlay" class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head" :class="getTypeClass(type)">
      <p class="modal-card-title">{{ title }}</p>
    </header>
    <section class="modal-card-body">
      <slot></slot>
    </section>
    <footer v-if="isConfirm" class="modal-card-foot">
      <button
        class="button"
        :class="[getTypeClass(type), {'is-loading': isLoading }]"
        @click="confirm"
      >
        Confirm
      </button>
      <button class="button" @click="dismiss">Cancel</button>
    </footer>
    <footer v-else class="modal-card-foot">
      <button class="button" @click="dismiss">OK</button>
    </footer>
  </div>
</div>
</template>

<script>
const types = {
  error: 'is-danger',
  warning: 'is-warning',
  info: 'is-info',
  success: 'is-success',
};

export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'info',
    },
    withOverlay: {
      type: Boolean,
      default: true,
    },
    isConfirm: {
      type: Boolean,
      default: false,
    },
    isAsync: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: true,
      isLoading: false,
    };
  },
  methods: {
    getTypeClass(type) {
      return types[type] || types.info;
    },
    dismiss() {
      this.isActive = false;
      this.$emit('dismissed');
    },
    confirm() {
      if (this.isAsync) {
        this.isLoading = true;
      } else {
        this.isActive = false;
      }

      this.$emit('confirmed');
    },
  },
};
</script>

<style scoped lang="scss">
  @import 'bulma/sass/utilities/initial-variables.sass';
  @import 'bulma/sass/utilities/functions.sass';
  @import 'bulma/sass/utilities/derived-variables.sass';

  .modal-card-head {
  text-transform:capitalize;

  &.is-danger {
    background-color: $danger;
  }
  &.is-warning {
    background-color: $warning;
  }
  &.is-info {
    background-color: $info;
  }
  &.is-success {
    background-color: $success;
  }

  &.is-danger,
  &.is-info,
  &.is-success {
    .modal-card-title {
        color: $text-invert;
    }
  }
}
</style>
