import requests from '@/services/api/request/index';
import dispatch from '@/services/api/dispatcher';
import ApiError from '@/error/apiError';

const getRequestBuilder = (path) => {
  let requestBuilder = requests;
  path.split('/').forEach((segment) => { requestBuilder = requestBuilder[segment]; });
  return requestBuilder;
};

export default {
  async fetch(resourcePath, params, middlewares = []) {
    const requestBuilder = getRequestBuilder(resourcePath)(params);
    middlewares.forEach((middleware) => requestBuilder.addMiddleware(middleware));
    try {
      return await dispatch(requestBuilder);
    } catch (error) {
      console.log('fetch error');
      if (!(error instanceof ApiError) || error.statusCode !== 401) {
        throw error;
      }

      console.log('try again');
      // rotate keys and try again
      await dispatch(getRequestBuilder('auth/refresh')());
      return dispatch(requestBuilder);
    }
  },
};
