import { nanoid } from 'nanoid';

export default {
  namespaced: true,
  state: () => ({ items: [] }),
  mutations: {
    add(state, { type, message }) {
      state.items.push({
        id: nanoid(),
        type,
        message,
      });
    },
    remove(state, item) {
      state.items.splice(state.items.indexOf(item), 1);
    },
    clear(state) {
      state.items = [];
    },
  },
  actions: {
    add({ commit }, type, message) {
      commit('add', { type, message });
    },
    addError({ commit }, message) {
      commit('add', { type: 'error', message });
    },
    addWarning({ commit }, message) {
      commit('add', { type: 'warning', message });
    },
    addInfo({ commit }, message) {
      commit('add', { type: 'info', message });
    },
    addSuccess({ commit }, message) {
      commit('add', { type: 'success', message });
    },
    setError({ dispatch }, message) {
      dispatch('clear');
      dispatch('addError', message);
    },
    setWarning({ dispatch }, message) {
      dispatch('clear');
      dispatch('addWarning', message);
    },
    setInfo({ dispatch }, message) {
      dispatch('clear');
      dispatch('addInfo', message);
    },
    setSuccess({ dispatch }, message) {
      dispatch('clear');
      dispatch('addSuccess', message);
    },
    clear({ commit }) {
      commit('clear');
    },
    remove({ commit }) {
      commit('remove');
    },
  },
};
