import RequestBuilder from '@/services/api/requestBuilder';

export default {
  login(data) {
    return new RequestBuilder('POST', '/auth').setJsonBody(data);
  },
  refresh() {
    return new RequestBuilder('POST', '/auth/rotate');
  },
  logout() {
    const data = { allSessions: false };
    return new RequestBuilder('POST', '/auth/logout').setJsonBody(data);
  },
};
