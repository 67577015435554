<template>
  <button
    v-bind="attrs"
    class="button"
    :class="[ buttonClass, { 'is-loading': isLoading } ]"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'AsyncButton',
  props: ['buttonClass', 'actionHandler'],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    attrs() {
      return {
        ...this.$attrs,
        onClick: this.handleClick,
      };
    },
  },
  methods: {
    async handleClick(e) {
      this.isLoading = true;
      await this.$attrs.onHandle(e);
      this.isLoading = false;
    },
  },
};
</script>
