import apiClient from '@/services/api/client';

export default {
  namespaced: true,
  state: () => ({
    loggedIn: undefined,
  }),
  mutations: {
    setLoggedIn(state) {
      console.log('setLoggedIn');
      state.loggedIn = true;
    },
    setLoggedOut(state) {
      state.loggedIn = false;
      console.log('setLoggedOut');
    },
  },
  actions: {
    async login({ dispatch }, credentials) {
      await apiClient.fetch('auth/login', credentials);
      dispatch('fetchLoggedUserData');
    },
    async logout({ dispatch }) {
      await apiClient.fetch('auth/logout');
      dispatch('clearLoggedUserData');
    },
    async fetchLoggedUserData({ commit, dispatch }) {
      try {
        await dispatch('profile/fetch', null, { root: true });
        await dispatch('sport/getAll', null, { root: true });
        commit('setLoggedIn');
      } catch (error) {
        console.log(error);
        commit('setLoggedOut');
      }
    },
    clearLoggedUserData({ commit }) {
      commit('setLoggedOut');
      commit('profile/clear', null, { root: true });
      commit('sport/clear', null, { root: true });
    },
  },
  getters: {
    isPending(store) {
      return store.loggedIn === undefined;
    },
    isLoggedIn(store) {
      return store.loggedIn === true;
    },
    isLoggedOut(store) {
      return store.loggedIn === false;
    },
  },
};
