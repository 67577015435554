import apiClient from '@/services/api/client';

export default {
  namespaced: true,
  state: () => ({
    user: undefined,
    userPromise: undefined,
  }),
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setUserPromise(state, userPromise) {
      state.userPromise = userPromise;
    },
    clear(state) {
      state.user = null;
      state.userPromise = null;
    },
    updateSettings(state, settings) {
      state.user.settings = settings;
    },
  },
  actions: {
    async fetch({ commit }) {
      const promise = apiClient.fetch('user/getCurrent')
        .then((user) => {
          commit('setUser', user);
          return user;
        });

      commit('setUserPromise', promise);

      return promise;
    },
    async get({ state, dispatch }) {
      if (state.user) {
        return state.user;
      }
      if (state.userPromise) {
        return state.userPromise;
      }

      return dispatch('fetch');
    },
    logout({ commit }) {
      commit('clear');
    },
    async updateSettings({ commit }, settings) {
      const settingsResponse = await apiClient.fetch('user/updateSettings', settings);
      commit('updateSettings', settingsResponse);
    },
  },
  getters: {
    isStravaConnected(state) {
      if (!state.user) {
        return false;
      }
      return Boolean(state.user.integrations.strava.athleteId);
    },
  },
};
