import client from '@/services/api/client';
import ApiError from '@/error/apiError';

export default {
  install: (app) => {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$api = async (resource, params, middlewares = []) => {
      try {
        return await client.fetch(resource, params, middlewares);
      } catch (error) {
        if (error instanceof ApiError) {
          const { $router: router, $store: store } = app.config.globalProperties;
          if (error.statusCode === 401) {
            store.dispatch('notification/addWarning', 'Please authenticate.');
            router.push({ name: 'Login' });
          } else if (error.statusCode >= 500) {
            store.dispatch('notification/addError', 'Something went wrong');
          }
        }
        throw error;
      }
    };
  },
};
