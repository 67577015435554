import auth from './auth';
import user from './user';
import workout from './workout';
import sport from './sport';
import strava from './strava';
import stats from './stats';

export default {
  auth,
  user,
  workout,
  sport,
  strava,
  stats,
};
