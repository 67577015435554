import {
  addMonths,
  subMonths,
  subDays,
} from 'date-fns';
import dateRangeUtils from '@/services/dateRange';

export default {
  namespaced: true,
  state: () => ({
    dateAfterEnabled: true,
    dateAfter: dateRangeUtils.getDefaultAfter(),
    dateBeforeEnabled: true,
    dateBefore: dateRangeUtils.getDefaultBefore(),
    sport: null,
    isLoading: false,
    isPending: false,
    isCancellable: false,
    progress: '',
  }),
  mutations: {
    set(state, { field, value }) {
      state[field] = value;
    },
  },
  actions: {
    set({ commit }, { field, value }) {
      commit('set', { field, value });
    },
    setDateAfterEnabled({ commit }, value) {
      commit('set', { field: 'dateAfterEnabled', value });
    },
    setDateBeforeEnabled({ commit }, value) {
      commit('set', { field: 'dateBeforeEnabled', value });
    },
    setDateAfter({ commit }, value) {
      commit('set', { field: 'dateAfter', value });
    },
    setDateBefore({ commit }, value) {
      commit('set', { field: 'dateBefore', value });
    },
    setInputDateAfter({ dispatch }, value) {
      dispatch('setDateAfter', dateRangeUtils.parseInput(value));
    },
    setInputDateBefore({ dispatch }, value) {
      dispatch('setDateBefore', dateRangeUtils.parseInput(value));
    },
    setSport({ commit }, value) {
      commit('set', { field: 'sport', value });
    },
    setPrevMonth({ state, dispatch }) {
      const prevMonthDate = subMonths(state.dateAfter, 1);

      dispatch('setDateAfterEnabled', true);
      dispatch('setDateBeforeEnabled', true);
      dispatch('setDateAfter', dateRangeUtils.getMonthlyAfter(prevMonthDate));
      dispatch('setDateBefore', dateRangeUtils.getMonthlyBefore(prevMonthDate));
    },
    setNextMonth({ state, dispatch }) {
      const nextMonthDate = addMonths(subDays(state.dateBefore, 1), 1);

      dispatch('setDateAfterEnabled', true);
      dispatch('setDateBeforeEnabled', true);
      dispatch('setDateAfter', dateRangeUtils.getMonthlyAfter(nextMonthDate));
      dispatch('setDateBefore', dateRangeUtils.getMonthlyBefore(nextMonthDate));
    },
    setProgress({ commit }, value) {
      commit('set', { field: 'progress', value });
    },
    setLoadingStarted({ commit, dispatch }, { isCancellable = false } = {}) {
      dispatch('setProgress', '');
      commit('set', { field: 'isCancellable', value: isCancellable });
      commit('set', { field: 'isLoading', value: true });
      commit('set', { field: 'isPending', value: !isCancellable });
    },
    setLoadingStopped({ commit }) {
      commit('set', { field: 'isLoading', value: false });
      commit('set', { field: 'isPending', value: false });
    },
    setPending({ commit }) {
      commit('set', { field: 'isPending', value: true });
    },
    setActive({ commit }) {
      commit('set', { field: 'isPending', value: false });
    },
  },
  getters: {
    inputDateAfter(store) {
      return dateRangeUtils.formatInput(store.dateAfter);
    },
    inputDateAfterMax(store) {
      if (!store.dateBeforeEnabled) {
        return '3000-01-01';
      }
      return dateRangeUtils.formatInput(dateRangeUtils.getMaxAfter(store.dateBefore));
    },
    inputDateBefore(store) {
      return dateRangeUtils.formatInput(store.dateBefore);
    },
    inputDateBeforeMin(store) {
      if (!store.dateAfterEnabled) {
        return '1900-01-01';
      }
      return dateRangeUtils.formatInput(dateRangeUtils.getMinBefore(store.dateAfter));
    },
    filterObject(store) {
      const filter = {
        ...store.dateAfterEnabled ? { after: store.dateAfter.toISOString() } : {},
        ...store.dateBeforeEnabled ? { before: store.dateBefore.toISOString() } : {},
        ...store.sport ? { 'sport.id': store.sport.id } : {},
      };

      return filter;
    },
  },
};
