import RequestBuilder from '@/services/api/requestBuilder';

export default {
  register(data) {
    return new RequestBuilder('POST', '/users').setJsonBody(data);
  },
  getCurrent() {
    return new RequestBuilder('GET', '/users/me');
  },
  changePassword(data) {
    return new RequestBuilder('PUT', '/users/me/password').setJsonBody(data);
  },
  updateSettings(data) {
    return new RequestBuilder('PATCH', '/users/me/settings').setJsonBody(data);
  },
};
