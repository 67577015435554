<template>
    <header class="app-header">
      <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <a class="navbar-item" href="/">
            <img src="@/assets/images/logo.png" width="32" height="32" alt="My GPS Workouts" />
          </a>
          <router-link
            class="navbar-item"
            active-class="is-active"
            :to="{ name: 'Home' }"
          >
            My GPS Workouts
          </router-link>
          <AutoSync />

          <a role="button"
            aria-label="menu"
            aria-expanded="false"
            @click="showNav = !showNav"
            :class="{ 'is-active':showNav }"
            class="navbar-burger burger"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div class="navbar-menu" :class="{ 'is-active' : showNav }" @click="showNav = false">
          <div class="navbar-start">
            <PageHeaderNav />
          </div>
          <div class="navbar-end">
            <div class="strava-logo">
              <img src="@/assets/images/api_logo_cptblWith_strava_stack_gray.png"
              width="100"
              height="43"
              alt="ompatible with Strava"
              />
            </div>
            <PageHeaderProfile />
          </div>
        </div>
      </nav>
    </header>
</template>

<script>
import PageHeaderNav from '@/components/pageHeader/Nav.vue';
import AutoSync from '@/components/pageHeader/AutoSync.vue';
import PageHeaderProfile from '@/components/pageHeader/Profile.vue';

export default {
  name: 'PageHeader',
  components: {
    PageHeaderNav,
    AutoSync,
    PageHeaderProfile,
  },
  data() {
    return {
      showNav: false,
    };
  },
};
</script>

<style scoped lang="scss">
  @import '~bulma';

  .app-header {
    .navbar {
      box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.1);
      @include from($tablet) {
        box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.9);
      }
      .strava-logo {
        display: flex;
        align-self: center;
      }
    }
  }
</style>
