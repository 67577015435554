class ApiError extends Error {
  static fromResponse(responseBody, statusCode) {
    const error = new ApiError('API Error');
    error.responseBody = responseBody;
    error.statusCode = statusCode;

    return error;
  }

  getMessage() {
    const fallback = 'Something went wrong.';

    if (this.statusCode >= 500 || !this.responseBody) {
      return fallback;
    }

    return this.responseBody.message || fallback;
  }

  getType() {
    if (!this.responseBody || !this.responseBody.type) {
      return 'unknown';
    }
    return this.responseBody.type;
  }

  isDuplicate() {
    return this.getType() === 'duplicateError';
  }
}

export default ApiError;
