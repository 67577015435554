import RequestBuilder from '@/services/api/requestBuilder';

export default {
  connect(data) {
    return new RequestBuilder('PUT', '/users/me/integrations/strava/oauth').setJsonBody(data);
  },
  getWorkouts(filters) {
    const queryString = filters ? `?${new URLSearchParams(filters).toString()}` : '';
    return new RequestBuilder('GET', `/users/me/integrations/strava/workouts${queryString}`);
  },
  sync(id) {
    return new RequestBuilder('POST', `/users/me/integrations/strava/workouts/${id}`);
  },
};
